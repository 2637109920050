import React from 'react';

import './BannerPepsico.scss';

const BannerPepsico = () => {
  return (
    <div className={'banner-pepsico'}>
      Уважаемые пользователи!
      <br/>
      В данный момент заказ карт пополнения сотовой связи остановлен.
      <br/>
      Приносим свои извинения за доставленные неудобства.
    </div>
  );
};

export default BannerPepsico;
