import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {InvisibleSmartCaptcha} from '@yandex/smart-captcha';

import {siteKey} from 'env/env.json';

import {getSearchForOtherProject, useAppDispatch} from 'utils/hooks';

import {errorTimer} from 'utils/errorTimer';
import {rusLettersReg} from 'utils/stringFormatter';
import {IStore} from 'store/store';
import {hidePopup} from 'store/components/PopupSlice';
import {signUp} from 'store/components/AuthSlice';

import Button from 'components/button/Button';
import SecondInput from 'components/secondInput/SecondInput';

import logo from './assets/image/logo.svg';
import close from './assets/image/close.svg';

import './Register.scss';

const Register = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {search} = useLocation();

  const visiblePopup = useSelector((state: IStore) => state.popup.popup);
  const userToken = useSelector((state: IStore) => state.auth.user?.token);
  const userRefreshToken = useSelector((state: IStore) => state.auth.user?.refresh_token);
  const is_required_email = useSelector((state: IStore) => state.auth.is_required_email);
  const propsPopup = useSelector((state: IStore) => state.popup.props);

  const [email, setEmail] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [secondName, setSecondName] = useState<string>('');
  const [captcha, setCaptcha] = useState<string>('');
  const [visible, setVisible] = useState(true);
  const [resetCaptcha, setResetCaptcha] = useState(1);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const [emailError, setEmailError] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  const [secondNameError, setSecondNameError] = useState<string>('');
  const [captchaError, setCaptchaError] = useState<string>('');

  const [showImagesForOtherProject, setShowImagesForOtherProject] = useState<boolean>(false);

  const disabledButton =
    (name && secondName) === ''
    || (is_required_email && email === '')
    || captcha === ''
    || emailError !== ''
    || nameError !== ''
    || secondNameError !== '';

  const closePopup = () => {
    propsPopup.onClose && propsPopup.onClose();
    dispatch(hidePopup('register'));
  };

  const signUpForm = () => {
    if (!disabledButton) {
      setLoading(true);

      const signUpData = {
        name: name,
        surname: secondName,
        email,
        history: history,
        captcha: captcha,
        token: userToken ? userToken : '',
        refreshToken: userRefreshToken ? userRefreshToken : '',
      };

      dispatch(signUp(signUpData))
        .then((res: any) => {
          setLoading(false);
          setResetCaptcha((prev) => prev + 1);
          setCaptcha('');

          if (res.payload?.code === 'error') {
            const error = res.payload.result;
            if (Object.keys(error).includes('name')) {
              errorTimer(setNameError, error.name[0]);
            }
            if (Object.keys(error).includes('email')) {
              errorTimer(setEmailError, error.email[0]);
            }
            if (Object.keys(error).includes('surname')) {
              errorTimer(setSecondNameError, error.surname[0]);
            }
            if (Object.keys(error).includes('captcha_response')) {
              errorTimer(setCaptchaError, error.captcha_response[0]);
            }
          }
        });
    }
  };

  const onKeyPressHandler = (event: any) => {
    if (event.keyCode === 13) {
      signUpForm();
    }
  };

  const checkLangInEmail = (value: string) => {
    if (
      (/^[a-zA-Z0-9@./'&_-]+$/.test(value))
        && value.length <= 50
        || value.length === 0
    ) {
      setEmailError('');
      setEmail(value);
    } else {
      errorTimer(setEmailError, 'Ввод недопустимых символов');
    }
  };

  const changeName = (value: string) => {
    if ((rusLettersReg.test(value) || value.length === 0) && value.length <= 50) {
      setNameError('');
      setName(value);
    } else {
      errorTimer(setNameError, 'Ввод недопустимых символов');
    }
  };

  const changeCaptcha = (token: string | null) => {
    if (token) {
      setCaptcha(token);
    }
  };

  const changeSecondName = (value: string) => {
    if ((rusLettersReg.test(value) || value.length === 0) && value.length <= 50) {
      setSecondNameError('');
      setSecondName(value);
    } else {
      errorTimer(setSecondNameError, 'Ввод недопустимых символов');
    }
  };

  useEffect(() => {
    setEmail('');
    setName('');
    setSecondName('');
    setEmailError('');
    document.body.style.overflow = visiblePopup['register'] ? 'hidden' : 'visible';
  }, [visiblePopup['register']]);

  useEffect(() => {
    getSearchForOtherProject(search, setShowImagesForOtherProject);
  }, []);

  return (
    <div
      className={
        visiblePopup['register']
          ? 'register register_active'
          : 'register'}
      onKeyUp={onKeyPressHandler}>
      {
        !showImagesForOtherProject ? (
          <>
            <img src={close} alt="close" className={'register__close'} onClick={closePopup} />
            <img src={logo} alt="logo" className={'register__logo'} onClick={closePopup} />
          </>
        ) : null
      }
      <div className={'register__container'}>
        <h3 className={'register__title'}>Осталось немного, заполните личные данные</h3>
        <p className={'register__info'}>
          <span className={'register__info_color-pink'}>*</span>{' '}
          Все поля обязательны к заполнению
        </p>
        <SecondInput value={name} error={nameError} setValue={changeName} title={'Имя'} marginBottom={16} requery />
        <SecondInput
          value={secondName}
          error={secondNameError}
          setValue={changeSecondName}
          title={'Фамилия'}
          requery
          marginBottom={16}
        />
        <SecondInput
          value={email}
          setValue={checkLangInEmail}
          error={emailError}
          title={'E-mail'}
          type={'text'}
          requery={is_required_email}
          marginBottom={16}
        />
        <div className={'register__captcha'}>
          {
            visiblePopup['register'] ? (
              <InvisibleSmartCaptcha
                key={resetCaptcha}
                hideShield
                sitekey={siteKey}
                visible={visible}
                onSuccess={changeCaptcha}
                onTokenExpired={() => setCaptcha('')}
              />
            ) : null
          }
          <span
            className={`register__captcha-error ${captchaError ? 'register__captcha-error_active' : ''}`}>
            {captchaError}
          </span>
        </div>
        <Button
          onClick={signUpForm}
          width={'100%'}
          loading={loading}
          marginBottom={30}
          borderRadius={8}
          disabled={disabledButton || loading}>
          Регистрация
        </Button>
      </div>
    </div>
  );
};

export default Register;
