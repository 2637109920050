import React, {useEffect, useState} from 'react';

import {StorageItemsNames} from 'enums';
import {useHistory} from 'react-router-dom';
import {useAppSelector} from 'utils/hooks';

import Button from 'components/button/Button';

import {GetIcon} from 'store/enums/normalizedIcons';

import bannerImage from './assets/images/bannerImage.svg';

import './NDFLPage.scss';

const StatusNDFL = ({status}: {status: number}) => {
  const colors = useAppSelector((state) => state.settings.colors);
  const domain = useAppSelector((state) => state.settings.currentDomainName);

  const [reloadButtonDisabled, setReloadButtonDisabled] = useState(false);

  const {loadButtonColor} = colors[domain];

  const min = 60000;

  const timer = (time: number) => {
    setTimeout(() => {
      setReloadButtonDisabled(false);
    }, time);
  };

  const reloadStatus = () => {
    setReloadButtonDisabled(true);
    if (!reloadButtonDisabled) {
      const timeStamp = localStorage.getItem(StorageItemsNames.TIME);
      if (timeStamp && (new Date().getTime() - parseInt(timeStamp) < min)) {
        const time = new Date().getTime() - parseInt(timeStamp);
        timer(time);
      } else {
        localStorage.setItem(StorageItemsNames.TIME, JSON.stringify(new Date().getTime()));
        timer(min);
      }
    }
  };

  useEffect(() => {
    const timeStamp = localStorage.getItem(StorageItemsNames.TIME);
    if (timeStamp && (new Date().getTime() - parseInt(timeStamp) < min)) {
      const time = new Date().getTime() - parseInt(timeStamp);
      setReloadButtonDisabled(true);
      timer(time);
    }
  }, []);

  const statusValues = [
    {
      name: 'Нет данных',
      className: '',
      icon: <GetIcon iconName={'AcceptStatusIcon'} />,
      reload: true,
    },
    {
      name: 'В обработке',
      className: 'wait',
      icon: <GetIcon iconName={'WaitingStatusIcon'} />,
      reload: true,
    },
    {
      name: 'Принято',
      className: 'ready',
      icon: <GetIcon iconName={'AcceptStatusIcon'} />,
      reload: false,
    },
    {
      name: 'Отказ',
      className: 'reject',
      icon: <GetIcon iconName={'RejectStatusIcon'} />,
      reload: false,
    },
  ];
  return (
    <div className={'ndfl__status-container'}>
      <div className={`ndfl__status ndfl__status_${statusValues[status].className}`}>
        {statusValues[status].icon}
        {statusValues[status].name}
      </div>
    </div>
  );
};

const NDFLPage = ({onClick}: {onClick: () => void}) => {
  const history = useHistory();

  const goToHelp = () => {
    history.push('/dashboard/help');
  };

  const tax: any = useAppSelector(({auth: {user}}) => {
    if (!user?.tax) {
      return {};
    }

    const data = {
      data: {
        'Статус': <StatusNDFL status={user?.tax?.status}/>,
        'ID Анкеты': user?.tax?.id,
        'Дата создания': new Date(user?.tax?.created_at).toLocaleDateString(),
        'Комментарий к анкете': user?.tax?.status === 3 ? (user?.tax?.comment): undefined
      },
      status: user?.tax?.status
    };
    
    return data;
  });

  const click = () => {
    onClick();
  };

  return (
    <div className={'ndfl__container'}>
      <div>
        <div className={'ndfl__table'}>
          {Object.entries(tax.data).map((item: any, index) => {
            if (item[1] === undefined) {
              return;
            }
            return (
              <div key={item[0]} className={'ndfl__data-row'}>
                <div className={'ndfl__data-head'}>
                  {item[0]}
                </div>
                <div className={`ndfl__data-body ${index === 3 ? 'ndfl__color_red' : ''}`}>
                  {item[1]}
                </div>
              </div>
            );
          })}
          {
            tax?.status === 3 ? (
              <>
                <div className={'ndfl__warning-message'}>
                  <GetIcon iconName={'WarningIcon'} />
                  <span>
                    Заполните анкету НДФЛ повторно, внесите данные о себе корректно.
                  </span>
                </div>
                <Button className={'ndfl__button'} onClick={click}>
                  Заполнить анкету
                </Button>
              </>
            ) : null
          }
        </div>
      </div>
      <div className={'ndfl__banner'}>
        <img src={bannerImage} className={'ndfl__banner-image'} alt={'image'} />
        <div className={'ndfl__banner-title'}>
          Что такое НДФЛ и почему его нужно платить?
        </div>
        <div className={'ndfl__banner-text'}>
          НДФЛ — это налог на доходы физических лиц.
          Взимается не только с заработной платы, но и с доходов,
          полученных через программы лояльности или другие источники прибыли.
          Зачем его платить? НДФЛ нужен для финансирования различных государственных
          нужд — медицины, образования, обороны и других сфер. Поэтому, уплачивая НДФЛ,
          вы помогаете обществу и участвуете в формировании бюджета страны.
        </div>

        <div onClick={goToHelp} className={'ndfl__banner-link'}>
          Больше информации в разделе помощь
        </div>
      </div>
    </div>
  );
};

export default NDFLPage;
