import {Dispatch, SetStateAction, useRef, useState, useEffect} from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import type { IStore, AppDispatch } from 'store/store';
import {showPopup} from '../store/components/PopupSlice';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<IStore> = useSelector;

export const useFocus = () => {
  const htmlElRef = useRef<any>(null);
  const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus();};

  return [ htmlElRef, setFocus ];
};

export const getSearchForOtherProject = (
  search: string,
  setState?: Dispatch<SetStateAction<boolean>>,
  dispatch?: any,
  openPopup?: any
) => {
  const searchArray =
    search.replace('?', '').split('=');

  if (searchArray[0] === 'auth' && searchArray[1] === 'open-popup' && openPopup === 'auth') {
    setState && setState(true);
    openPopup && dispatch(showPopup(openPopup));

    return true;
  } else if (searchArray[0] === 'auth' && searchArray[1] === 'open-auth' && openPopup === 'auth') {
    openPopup && dispatch(showPopup(openPopup));
  } else if (searchArray[0] === 'card-balance' && searchArray[1] === 'open-popup' && openPopup === 'checkCard') {
    openPopup && dispatch(showPopup(openPopup));
  } else {
    setState && setState(false);

    return false;
  }
};

export const useMediaQuery = (maxWidth: number) =>  {
  const [isMatching, setIsMatching] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const mediaQueryList = window.matchMedia(`(max-width: ${maxWidth}px)`);
      const handleChange = () => setIsMatching(mediaQueryList.matches);

      setIsMatching(mediaQueryList.matches);

      mediaQueryList.addEventListener('change', handleChange);

      return () => mediaQueryList.removeEventListener('change', handleChange);
    }
  }, [maxWidth]);

  return isMatching;
};

export const useCheckScrollbar = (ref: any) => {
  const [verticalScroll, setVerticalScroll] = useState(false);
  const [horizontalScroll, setHorizontalScroll] = useState(false);

  useEffect(() => {
    const checkScrollbars = () => {
      const element = ref.current;

      if (!element) return;

      setVerticalScroll(element.scrollHeight > element.clientHeight);
      setHorizontalScroll(element.scrollWidth > element.clientWidth);
    };

    // Проверяем при монтировании
    checkScrollbars();

    // Слушаем изменения размера окна
    const handleResize = () => {
      checkScrollbars();
    };

    window.addEventListener('resize', handleResize);

    // Очистка эффекта при размонтировании
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);

  return {verticalScroll, horizontalScroll};
};

export const useResize = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = (event: any) => {
      setWidth(event.target.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    width,
  };
};

export const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
