import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import ax from 'utils/ax';

export interface IGetBalanceData {
  code: string,
}

export interface IActivationEko {
  code: string,
  type: 'qiwi' | 'phone',
  phone: string,
  card: string
}

export interface IHistoryEkoItem {
  id: number,
  number: string,
  nominal: number,
  recipient_number: string,
  payment_type: number,
  user_id: number,
  created_at: string,
  updated_at: string
}

export const getBalanceEko = createAsyncThunk(
  'eko/getBalance',
  async (payload: IGetBalanceData) => {
    try {
      const {data} = await ax().post(
        'api/eko/cards/get-balance',
        payload
      );
      const {result} = data.data;
      return result[0];
    } catch (e) {
      console.log(e);
    }
  }
);

export const activationEko = createAsyncThunk(
  'eko/activationEko',
  async (payload: IActivationEko) => {
    try {
      const {data} = await ax().post(
        'api/eko/cards/activate',
        payload
      );
      const {result} = data.data;
      return result;
    } catch (e) {
      console.log(e);
    }
  }
);

export const historyEKO = createAsyncThunk(
  'eko/historyEko',
  async () => {
    try {
      const {data} = await ax().post(
        'api/eko/cards/activate/history'
      );
      const {result} = data.data;
      return result.cards;
    } catch (e) {
      console.log(e);
    }
  }
);

export interface IEkoState {
  data: any;
  description: string;
  status: number;
  history: IHistoryEkoItem[];
  loading: boolean;
}

const EKOSlice = createSlice({
  name: 'eko',
  initialState: {
    data: {},
    description: '',
    status: NaN,
    history: [],
    loading: false,
  },
  reducers: {
    clearData: (state) => {
      state.data = {};
      state.description = '';
      state.status = NaN;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getBalanceEko.fulfilled, (state: IEkoState, {payload}) => {
      state.data = payload;

      if (payload?.status === 0) {
        state.status = payload.status;

        if (payload?.description) {
          state.description = payload.description;
        }
      }
      state.loading = false;
    });
    builder.addCase(getBalanceEko.pending, (state: IEkoState) => {
      state.loading = true;
    });
    builder.addCase(getBalanceEko.rejected, (state: IEkoState) => {
      state.loading = false;
    });
    builder.addCase(activationEko.fulfilled, (state: IEkoState, {payload}) => {
      state.description = payload[0].description;
      state.status = payload[0].status;
      state.loading = false;
    });
    builder.addCase(activationEko.pending, (state: IEkoState) => {
      state.loading = true;
    });
    builder.addCase(activationEko.rejected, (state: IEkoState) => {
      state.loading = false;
    });
    builder.addCase(historyEKO.fulfilled, (state: IEkoState, {payload}) => {
      state.history = payload;
      state.loading = false;
    });
    builder.addCase(historyEKO.pending, (state: IEkoState) => {
      state.loading = true;
    });
    builder.addCase(historyEKO.rejected, (state: IEkoState) => {
      state.loading = false;
    });
  },
});

export default EKOSlice.reducer;
export const {clearData} = EKOSlice.actions;

