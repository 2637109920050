import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {showPopup} from 'store/components/PopupSlice';
import {IStore} from 'store/store';
import {GetIcon} from '../../../../../store/enums/normalizedIcons';

import Link from 'components/link/Link';
import Button from 'components/button/Button';

import jivochat from './assets/images/jivochat.svg';
import telegram from './assets/images/telegram.svg';
import whatsapp from './assets/images/whatsapp.svg';

interface IButtonContainer {
    openJivo: () => void;
}

const ButtonContainer:React.FC<IButtonContainer> = ({openJivo}) => {
  const dispatch = useDispatch();

  const colors = useSelector((state: IStore) => state.settings.colors);
  const domain = useSelector((state: IStore) => state.settings.currentDomainName);

  const {enterIconColor} = colors[domain];
  const isPepsico = window.location.href.includes('pepsi');

  return (
    <div className={'header__button-container'}>
      <div className={'header__button-container'}>
        <div className={'header__help-container'} data-is-pepsico={isPepsico}>
          <div className={'header__jivochat-container'} onClick={openJivo}>
            <img src={jivochat} alt="jivochat" className={'header__jivochat-icon'}/>
            <Link>Jivo-чат</Link>
          </div>
          <a
            href={'https://t.me/presentcard_bot'}
            target={'_blank'}
            rel="noreferrer"
            className={'header__telegram-container'}>
            <img src={telegram} alt="telegram" className={'header__telegram-icon'}/>
            <Link>presentcard_bot</Link>
          </a>
          <a
            href={'https://wa.me/79016345854'}
            target={'_blank'}
            rel="noreferrer"
            className={'header__whatsapp-container'}>
            <img src={whatsapp} alt="telegram" className={'header__whatsapp-icon'}/>
            <Link>WhatsApp</Link>
          </a>
        </div>
        {isPepsico ? (
          <span className={'header__phone-info-container'}>
            <span>Телефон для связи:{' '}</span>
            <a className={'header__phone'} href={'tel:+7 (499) 455-99-35'}>+7 (499) 455-99-35</a>
          </span>
        ) : null}
        <div className={'header__auth-container'}>
          <Button
            icon={<GetIcon iconName={'User'} fill={enterIconColor} secondaryFill={enterIconColor} />}
            width={191}
            className={'header__button'}
            backgroundColor={'#F45384'}
            onClick={() => dispatch(showPopup('auth'))}>
            Войти
          </Button>
          <div className={'header__link-container header__link-container_active'}>
            <Button
              icon={<GetIcon iconName={'Card'} fill={enterIconColor} secondaryFill={enterIconColor} />}
              width={191}
              className={'header__button'}
              backgroundColor={'rgb(70, 97, 196)'}
              onClick={() => dispatch(showPopup('checkCard'))}>
              Проверить баланс
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ButtonContainer;
