import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';

import {useAppDispatch, useAppSelector} from 'utils/hooks';

import {getUser} from 'store/components/ProfileSlice';
import {getProjectSettings, logOutAndResetStore} from 'store/components/AuthSlice';
import {showPopup} from 'store/components/PopupSlice';
import {setLoading} from 'store/components/PrizesSlice';
import {getCart} from 'store/components/CartSlice';

import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';

import NDFLPage from './assets/components/NDFLPage/NDFLPage';
import TabBar from './assets/components/tabBar/TabBar';
import Prizes from './assets/components/prizes/Prizes';
import History from './assets/components/history/History';
import Help from './assets/components/help/Help';
import Profile from './assets/components/profile/Profile';
import OrderCard from './assets/components/orderCard/OrderCard';
import Cart from './assets/components/cart/Cart';
import PlaceOrder from './assets/components/placeOrder/PlaceOrder';
import CompletedOrder from './assets/components/completedOrder/CompletedOrder';
import RejectOrder from './assets/components/rejectOrder/RejectOrder';
import DeficiencyBalance from './assets/components/deficiencyBalance/DeficiencyBalance';
import TimerLeftOut from './assets/components/timerLeftOut/TimerLeftOut';
import ActivationCard from './assets/components/activationCard/ActivationCard';
import RejectActivatedCard from './assets/components/rejectActivatedCard/RejectActivatedCard';
import CompletedActivationCard from './assets/components/completedActivationCard/CompletedActivationCard';
import CardsOver from './assets/components/cardsOver/CardsOver';
import TermsOfUse from './assets/components/orderCard/assets/components/termsOfUse/TermsOfUse';
import NoWallet from './assets/components/noWallet/NoWallet';
import RejectTransfer from './assets/components/rejectTransfer/RejectTransfer';
import CompletedTransfer from './assets/components/completedTransfer/CompletedTransfer';
import SettingPopup from './assets/components/prizes/assets/components/headerPrizes/assets/components/settingPopup/SettingPopup';
import OrderStatusPopup from 'components/orderStatusPopup/OrderStatusPopup';
import {EKO} from './assets/components/EKOTabs/EKOTabs';
import GoToAccount from '../../components/goToAccount/GoToAccount';
import EKOPopup from './assets/components/EKOPopup/EKOPopup';
import Auth from '../mainPage/assets/components/auth/Auth';
import Register from '../mainPage/assets/components/register/Register';
import TabMenu from 'components/tabMenu/TabMenu';
import ChangeUserData from './assets/components/changeUserData/ChangeUserData';
import MoneyTransfer from './assets/components/MoneyTransfer/MoneyTransfer';
import ContactManager from './assets/components/contactManager/ContactManager';
import TooManyAttempts from '../../components/TooManyAttempts/TooManyAttempts';
import AcceptByWithAllOrder from './assets/components/acceptByWithAllOrder/AcceptByWithAllOrder';
import MoneyTransferAlert from './assets/components/moneyTransferAlert/MoneyTransferAlert';
import InsufficientData from './assets/components/insufficientData/InsufficientData';
import CompletedFastOrder from './assets/components/completedFastOrder/CompletedFastOrder';
import MoneyTransferReject from './assets/components/moneyTransferReject/MoneyTransferReject';
import MoneyTransferSuccess from './assets/components/moneyTransferSuccess/MoneyTransferSuccess';
import NDFL_Popup from './assets/components/NDFL_Popup/NDFL_Popup';
import TestografPopup from 'components/TestografPopup/TestografPopup';
import PlasticPrizes from './assets/components/plasticPrizes/PlasticPrizes';
import ErrorPlaceOrderPopup from './assets/components/errorPlaceOrderPopup/ErrorPlaceOrderPopup';

import './Dashboard.scss';

let timer:any;

const Dashboard = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const test = localStorage.getItem('tester');
  const currentDomain = useAppSelector(state => state.settings.currentDomainName);
  const allowAuth = useAppSelector(state => state.settings.allowAuth);

  const user = useAppSelector(state => state.auth.user);
  const purse = useAppSelector(state => state.dashboard.purse);
  const cart = useAppSelector(state => state.cart.cart);

  const lengthCart = cart?.length > 0;

  const tabBar = [
    {id: 'prizes', name: 'Электронные сертификаты', child: <Prizes />},
    {
      id: 'plastic-prizes',
      name: 'Пластиковые карты',
      child: <PlasticPrizes />,
      hide: !user!.purses.find(item => item.plastic_included)
    },
    {id: 'history', name: 'История заказов', child: <History />},
    {id: 'help', name: 'Помощь', child: <Help />},
    {
      id: 'activation-eko',
      name: 'Активация ЕКО',
      subtitle:
        currentDomain === 'pepsico'
      || currentDomain === 'default' ? 'Пополнение баланса телефона' : '',
      child: <EKO />,
      hide:
        window.location.href.includes('raiffeisen')
        || currentDomain == 'loto-pr'
        || currentDomain == 'aclub'
        || currentDomain == 'rus-exp'
        || currentDomain == 'leader-abc'
        || currentDomain == 'mts-mission'
        || currentDomain == 'stg'
        || currentDomain == 'rubin'
    },
    {id: 'profile', name: 'Профиль', child: <Profile />, hide: true },
    {id: 'money-transfer', name: 'Перевод на карту', child: <MoneyTransfer />, hide: true },
    {id: 'cart', name: 'Корзина', child: <Cart />, hide: true},
    {id: 'place-order', name: 'Оформление заказа', child: <PlaceOrder />, hide: true},
  ];

  useEffect(() => {
    dispatch(getUser());
    dispatch(setLoading({name: 'history', value: true}));
  }, []);

  useEffect(() => {
    if (test && test === 'true' || allowAuth) {
      return;
    } else {
      dispatch(logOutAndResetStore());
    }
  }, []);

  useEffect(() => {
    if (cart?.length > 0) {
      clearInterval(timer);
      timer = setInterval(() => {
        if (history.location.pathname !== '/'){
          history.push('/dashboard/prizes');
        }
        dispatch(showPopup('timerLeftOut'));
        dispatch(getCart());
      }, 20 * 60 * 1000);
    }
    if (cart?.length === 0) {
      clearInterval(timer);
    }
  }, [lengthCart]);

  useEffect(() => {
    dispatch(getCart());
    if (purse?.id) {
      dispatch(getProjectSettings(purse?.plastic_included ? 'delivery' : 'registration'));
    }
  }, [purse?.id]);

  return (
    <div className={'dashboard'}>
      <Header marginBottom={13} />
      <TabBar tabBar={tabBar} />
      <Footer className={'footer__container_mobile'}/>

      {/*POPUP*/}
      <OrderCard />
      <GoToAccount />
      <TooManyAttempts />
      <CompletedOrder />
      <MoneyTransferSuccess />
      <MoneyTransferReject />
      <CompletedFastOrder />
      <AcceptByWithAllOrder />
      <InsufficientData />
      <ChangeUserData />
      <RejectOrder />
      <EKOPopup />
      <DeficiencyBalance />
      <TimerLeftOut />
      <ActivationCard />
      <RejectActivatedCard />
      <CompletedActivationCard />
      <CardsOver />
      <TermsOfUse />
      <NoWallet />
      <RejectTransfer />
      <CompletedTransfer />
      <SettingPopup />
      <ContactManager />
      <OrderStatusPopup />
      <Auth />
      <Register />
      <MoneyTransferAlert />
      <NDFL_Popup />
      <TestografPopup />
      <ErrorPlaceOrderPopup />

      <TabMenu />
    </div>
  );
};

export default Dashboard;
