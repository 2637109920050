import React from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {IStore} from 'store/store';

import {hidePopup} from '../../store/components/PopupSlice';
import Popup from 'components/popup/Popup';

import './GoToAccount.scss';

const GoToAccount = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const errorMessage = useSelector((state: IStore) => state.popup.props.result);

  const goToAccount = () => {
    history.push('profile');
    dispatch(hidePopup('goToAccount'));
  };

  return (
    <Popup id={'goToAccount'}>
      <div className={'go-to-account__container'}>
        {errorMessage ? (
          <h2 className={'go-to-account__error-title'}>{errorMessage}</h2>
        ) : null}
        <div className={'go-to-account'}>
          Для изменения данных перейдите в
          <br/>
          <span onClick={goToAccount} className={'go-to-account__link'}>
            Личный кабинет
          </span>
        </div>
      </div>
    </Popup>
  );
};

export default GoToAccount;
