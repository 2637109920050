import React, {useEffect} from 'react';
import {Provider, useDispatch, useSelector} from 'react-redux';
import {HashRouter, Route, Switch} from 'react-router-dom';

import {IStore, store} from './store/store';
import {authRedirect, logOutAndResetStore, signInLaravel} from './store/components/AuthSlice';

import CompletedVerifiedEmail from './components/completedVerifiedEmail/CompletedVerifiedEmail';
import Menu from './components/header/assets/components/authorizedMenu/assets/components/menu/Menu';

import MainNavigation from 'navigation/mainNavigation';
import {setDomainName} from './store/components/SettingSlice';
import {showPopup} from './store/components/PopupSlice';
import DontAllowAuth from './pages/dashboard/assets/components/dontAllowAuth/DontAllowAuth';
import UserManual from 'components/userManual/UserManual';

const MTSTheme = React.lazy(() => import('./components/MTSStyle/MTSStyleComponent'));
const MascoglassTheme = React.lazy(() => import('./components/MascoglassStyleComponentn/MascoglassStyleComponent'));
const MtsMissionTheme = React.lazy(() => import('./components/MTSStyle/MTSMissionstyle'));
const RuExTheme = React.lazy(() => import('./components/ruexStyleComponent/RuExStyleComponent'));
const Leader_ABC_Theme = React.lazy(() => import('./components/leader_abc/Leader_ABC_StyleComponent'));
const BirthdayTheme = React.lazy(() => import('./components/BirthdayStyleComponent/BirthdayStyleComponent'));
const AClubTheme = React.lazy(() => import('./components/AClubStyleComponent/AClubStyleComponent'));
const SmegTheme = React.lazy(() => import('./components/SmegStyleComponent/SmegStyleComponent'));
const BrusiteTheme = React.lazy(() => import('./components/BruciteStyleComponent/BruciteStyleComponent'));
const MakfaTheme = React.lazy(() => import('./components/MakfaStyleComponent/MakfaStyleComponent'));
const NLotoPR_Theme = React.lazy(() => import('./components/NLotoPR_StyleComponent/NLotoPR_StyleComponent'));
const RaiffeisenTheme = React.lazy(() => import('./components/RaiffeisenStyleConponent/RaiffeisenStyleComponent'));
const StgTheme = React.lazy(() => import('./components/StgStyleComponent/StgStyleComponent'));
const RubinTheme = React.lazy(() => import('./components/RubinStyleComponent/RubinStyleComponent'));
const GazpromNewYearTheme = React.lazy(() => import('./components/GazpromNewYearStyleComponent/GazpromNewYearStyleComponent'));
const MegafonTheme = React.lazy(() => import('./components/MegafonStyleComponent/MegafonStyleComponent'));
const ProektirovanieTheme = React.lazy(() => import('./components/ProektirovanieStyleComponent/ProektirovanieStyleComponent'));
const RtmisTheme = React.lazy(() => import('./components/RtmisStyleComponent/RtmisStyleComponent'));
const Pharmacy366Theme = React.lazy(() => import('./components/Pharmacy366Component/Pharmacy366Component'));

const faviconPaths = [
  'asg',
  'brucite',
  'geo',
  'korovka',
  'makfa',
  'megafon',
  'mtsfirst',
  'mission',
  'nloto',
  'proektirovanie',
  'raiff',
  'smeg',
  'sargazarm',
  'tele2',
  'men-day',
  'aclub',
  'womens-day',
  'r-express',
  'mascoglass',
  'rubin'
];

const App: React.FC = () => {
  const href = window.location.href;

  useEffect(() => {
    if (href.includes('www.')) {
      window.location.replace(window.location.origin.replace('www.', ''));
    }
    if (
      href.includes('asg') ||
      href.includes('aclub') ||
      href.includes('brucite') ||
      href.includes('geo') ||
      href.includes('megafon') ||
      href.includes('mtsfirst') ||
      href.includes('mission') ||
      href.includes('nloto') ||
      href.includes('loto-pr') ||
      href.includes('loto') ||
      href.includes('makfa') ||
      href.includes('smeg') ||
      href.includes('proektirovanie') ||
      href.includes('men-day') ||
      href.includes('womens-day') ||
      href.includes('raiffeisen') ||
      href.includes('r-express') ||
      href.includes('mascoglass') ||
      href.includes('lider_abc') ||
      href.includes('pepsico') ||
      href.includes('birthday') ||
      href.includes('sargazarm') ||
      href.includes('rubin')
    ) {
      const robotsTag = document.querySelector('meta[name="robots"]');
      const yandexTag = document.querySelector('meta[name="yandex"]');

      if (robotsTag) {
        robotsTag.setAttribute('content', 'noindex');
      }

      if (yandexTag) {
        yandexTag.setAttribute('content', 'noindex');
      }
    }
  }, []);

  useEffect(() => {
    const head = document.getElementsByTagName('head');
    const href = window.location.href;
    const finded = faviconPaths.find((item) => href.includes(item) ? item : null);
    const domainName = finded ? `-${finded}` : '';
    const path = 'favicon/other-favicon/favicon';

    const faviconPath = `${path}${domainName}.ico`;
    const faviconPath16x16 = `${path}${domainName}16x16.png`;
    const faviconPath32x32 = `${path}${domainName}32x32.png`;

    for (const link of [faviconPath, faviconPath16x16, faviconPath32x32]) {
      const linkFavicon = document.createElement('link');
      linkFavicon.rel = 'icon';
      linkFavicon.type = 'image/png';
      linkFavicon.href = link;
      head[0].prepend(linkFavicon);
    }
  }, []);

  return (
    <Provider store={store}>
      <React.Suspense fallback={<></>}>
        {href.includes('mtsfirst') ? <MTSTheme/> : null}
        {href.includes('mts-') ||href.includes('mts.') ? <MtsMissionTheme/> : null}
        {href.includes('mascoglass') ? <MascoglassTheme/> : null}
        {href.includes('local') ? null : null}
        {href.includes('r-express') ? <RuExTheme/> : null}
        {href.includes('proektirovanie') ? <ProektirovanieTheme /> : null}
        {href.includes('megafon') ? <MegafonTheme/> : null}
        {href.includes('geo') ? <GazpromNewYearTheme/> : null}
        {href.includes('lider_abc') ? <Leader_ABC_Theme/> : null}
        {href.includes('366') ? <Pharmacy366Theme /> : null}
        {href.includes('rtmis') ? <RtmisTheme/> : null}
        {href.includes('rubin') ? <RubinTheme /> : null}
        {href.includes('birthday') ? <BirthdayTheme/> : null}
        {href.includes('makfa') ? <MakfaTheme/> : null}
        {href.includes('stg') ? <StgTheme/> : null}
        {href.includes('smeg') ? <SmegTheme/> : null}
        {href.includes('raiffeisen') ? <RaiffeisenTheme/> : null}
        {href.includes('loto') && href.includes('pr') ? <NLotoPR_Theme/> : null}
        {href.includes('aclub') ? <AClubTheme/> : null}
        {href.includes('brucite') ? <BrusiteTheme/> : null}
      </React.Suspense>
      <AppRoute />
    </Provider>
  );
};

const AppRoute: React.FC = () => {
  const href = window.location.href;
  const dispatch = useDispatch();

  const authorized = useSelector((state: IStore) => state.auth.authorized);
  const allowAuth = useSelector((state: IStore) => state.settings.allowAuth);
  const test = localStorage.getItem('tester');

  useEffect(() => {
    dispatch(signInLaravel());
    dispatch(authRedirect());
    dispatch(setDomainName(href));
  }, []);

  useEffect(() => {
    // hide icon jivo chat if max-width: 630px
    let iconJivo;

    const interval = setInterval(() => {
      iconJivo = document.querySelectorAll('jdiv jdiv jdiv jdiv')[0];

      if ((window.matchMedia('(max-width: 630px)').matches)) {
        if (!window.location.href.includes('dashboard')) {
          iconJivo && iconJivo.setAttribute(
            'style',
            `
            opacity: 1;
            background: linear-gradient(95deg, rgb(47, 50, 74) 20%, rgb(66, 72, 103) 80%);
          `);

          return;
        }

        iconJivo && iconJivo.setAttribute('style', 'display: none; opacity: 1');
      }

      clearInterval(interval);
    }, 1000);

    iconJivo && clearInterval(interval);
  }, [authorized]);

  useEffect(() => {
    if (!allowAuth && (test !== 'true')) {
      dispatch(showPopup('dontAllowAuth'));
      dispatch(logOutAndResetStore());
    }
  }, []);

  return (
    <HashRouter>
      {allowAuth || test === 'true' ? (
        <Switch>
          <Route exact path="/manual" component={UserManual} />
          <MainNavigation />
        </Switch>
      ) : null}
      <CompletedVerifiedEmail />
      <Menu />
      <DontAllowAuth />

    </HashRouter>
  );
};

export default App;
