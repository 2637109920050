import {ISettingItem} from '../components/SettingSlice';

export const Colors: {[key: string]: ISettingItem} = {
  'mtsfirst' : {
    logoIconColor: '#fff',
    infoIcon: '#007CFF',
    linkIcon: '#007CFF',
    firstClipBoardColor: '#000',
    secondClipBoardColor: '#000',
    menuIconColor: '#969FA8',
    menuIconOpacity: '0.3',
    menuIconSecondaryOpacity: '1',
    enterIconColor: '#fff',
    firstColorIconIdle: '#969FA8',
    firstColorIconActive: '#ff0032',
    secondColorIconIdle: '#969FA8',
    secondColorIconActive: '#ff0032',
    loadButtonColor: '#000',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#000',
    cartIconOpacity: '1',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      secondaryOpacity: '1',
      mainColor: '#969FA8',
      secondColor: '#969FA8',
      activeMainColor: '#ff0032',
      activeSecondColor: '#969FA8',
      opacity: '1'
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '1',
    },
    crossIcon: {
      mainIcon: '#ff0032',
      secondIcon: '#fff',
      opacity: '1',
    },
  },
  'mts-mission' : {
    logoIconColor: '#fff',
    infoIcon: '#007CFF',
    linkIcon: '#007CFF',
    firstClipBoardColor: '#000',
    secondClipBoardColor: '#000',
    menuIconColor: '#969FA8',
    menuIconOpacity: '0.3',
    menuIconSecondaryOpacity: '1',
    enterIconColor: '#fff',
    firstColorIconIdle: '#969FA8',
    firstColorIconActive: '#ff0032',
    secondColorIconIdle: '#969FA8',
    secondColorIconActive: '#ff0032',
    loadButtonColor: '#000',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#000',
    cartIconOpacity: '1',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      secondaryOpacity: '1',
      mainColor: '#969FA8',
      secondColor: '#969FA8',
      activeMainColor: '#ff0032',
      activeSecondColor: '#969FA8',
      opacity: '1'
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '1',
    },
    crossIcon: {
      mainIcon: '#ff0032',
      secondIcon: '#fff',
      opacity: '1',
    },
  },
  'makfa' : {
    logoIconColor: '#fff',
    infoIcon: '#007934',
    linkIcon: '#2F80ED',
    firstClipBoardColor: '#E30613',
    secondClipBoardColor: '#E30613',
    menuIconColor: '#E30613',
    enterIconColor: '#E30613',
    menuIconOpacity: '0.3',
    menuIconSecondaryOpacity: '1',
    firstColorIconIdle: '#969FA8',
    firstColorIconActive: '#E30613',
    secondColorIconIdle: '#969FA8',
    secondColorIconActive: '#E30613',
    loadButtonColor: '#E30613',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#E30613',
    cartIconOpacity: '1',
    letter: {
      verified: '#007934',
      noVerified: '#ED1C24',
    },
    tabMenu: {
      secondaryOpacity: '1',
      mainColor: '#969FA8',
      secondColor: '#969FA8',
      activeMainColor: '#E30613',
      activeSecondColor: '#969FA8',
      opacity: '1'
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#E30613',
      secondIcon: '#fff',
      opacity: '1',
    },
  },
  'smeg' : {
    logoIconColor: '#fff',
    infoIcon: '#007934',
    linkIcon: '#2F80ED',
    firstClipBoardColor: '#E30613',
    secondClipBoardColor: '#E30613',
    menuIconColor: '#E30613',
    enterIconColor: '#E30613',
    menuIconOpacity: '0.3',
    menuIconSecondaryOpacity: '1',
    firstColorIconIdle: '#969FA8',
    firstColorIconActive: '#E30613',
    secondColorIconIdle: '#969FA8',
    secondColorIconActive: '#E30613',
    loadButtonColor: '#E30613',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#E30613',
    cartIconOpacity: '1',
    letter: {
      verified: '#007934',
      noVerified: '#ED1C24',
    },
    tabMenu: {
      secondaryOpacity: '1',
      mainColor: '#969FA8',
      secondColor: '#969FA8',
      activeMainColor: '#E30613',
      activeSecondColor: '#969FA8',
      opacity: '1'
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#E30613',
      secondIcon: '#fff',
      opacity: '1',
    },
  },
  'rubin': {
    logoIconColor: '#fff',
    infoIcon: '#5AC3BE',
    linkIcon: '#5AC3BE',
    firstClipBoardColor: '#0D94E0',
    secondClipBoardColor: '#0D94E0',
    menuIconColor: '#0D94E0',
    enterIconColor: '#0D94E0',
    menuIconOpacity: '0.3',
    menuIconSecondaryOpacity: '1',
    firstColorIconIdle: '#969FA8',
    loadButtonColor: '#2B2D33',
    firstColorIconActive: '#5AC3BE',
    secondColorIconIdle: '#969FA8',
    secondColorIconActive: '#0D94E0',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#2B2D33',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#479A78',
      noVerified: '#CD5A50',
    },
    tabMenu: {
      secondaryOpacity: '1',
      mainColor: '#969FA8',
      secondColor: '#969FA8',
      activeMainColor: '#0D94E0',
      activeSecondColor: '#969FA8',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '1'
    },
    crossIcon: {
      mainIcon: '#0D94E0',
      secondIcon: '#fff',
      opacity: '0.3',
    },
  },
  'stg': {
    logoIconColor: '#fff',
    infoIcon: '#5AC3BE',
    linkIcon: '#5AC3BE',
    firstClipBoardColor: '#F05C22',
    secondClipBoardColor: '#F05C22',
    menuIconColor: '#969FA8',
    enterIconColor: '#F05C22',
    menuIconOpacity: '0.3',
    menuIconSecondaryOpacity: '1',
    firstColorIconIdle: '#969FA8',
    loadButtonColor: '#00304F',
    firstColorIconActive: '#F05C22',
    secondColorIconIdle: '#969FA8',
    secondColorIconActive: '#F05C22',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#2B2D33',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#479A78',
      noVerified: '#CD5A50',
    },
    tabMenu: {
      secondaryOpacity: '1',
      mainColor: '#969FA8',
      secondColor: '#969FA8',
      activeMainColor: '#F05C22',
      activeSecondColor: '#969FA8',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '1'
    },
    crossIcon: {
      mainIcon: '#F05C22',
      secondIcon: '#fff',
      opacity: '1',
    },
  },
  'local' : {
    logoIconColor: '#fff',
    infoIcon: '#1BB961',
    linkIcon: '#004B80',
    firstClipBoardColor: '#ff0032',
    secondClipBoardColor: '#004B80',
    menuIconColor: '#004B80',
    enterIconColor: '#fff',
    menuIconOpacity: '0.3',
    menuIconSecondaryOpacity: '1',
    firstColorIconIdle: '#4661C4',
    loadButtonColor: '#004B80',
    firstColorIconActive: '#004B80',
    secondColorIconIdle: '#004B80',
    secondColorIconActive: '#004B80',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#004B80',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      secondaryOpacity: '1',
      mainColor: '#fff',
      secondColor: '#fff',
      activeMainColor: '#004B80',
      activeSecondColor: '#004B80',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#004B80',
      secondIcon: '#004B80',
      opacity: '0.3',
    },
  },
  gdnprof : {
    logoIconColor: '#fff',
    infoIcon: '#1BB961',
    linkIcon: '#0072BB',
    firstClipBoardColor: '#ff0032',
    secondClipBoardColor: '#0072BB',
    menuIconColor: '#0072BB',
    enterIconColor: '#fff',
    menuIconOpacity: '0.3',
    menuIconSecondaryOpacity: '1',
    firstColorIconIdle: '#0072BB',
    loadButtonColor: '#0072BB',
    firstColorIconActive: '#0072BB',
    secondColorIconIdle: '#0072BB',
    secondColorIconActive: '#0072BB',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#0072BB',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      secondaryOpacity: '1',
      mainColor: '#fff',
      secondColor: '#fff',
      activeMainColor: '#0072BB',
      activeSecondColor: '#0072BB',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#0072BB',
      secondIcon: '#0072BB',
      opacity: '0.3',
    },
  },
  rtmis : {
    logoIconColor: '#fff',
    infoIcon: '#1BB961',
    linkIcon: '#0169EC',
    firstClipBoardColor: '#ff0032',
    secondClipBoardColor: '#0169EC',
    menuIconColor: '#0169EC',
    enterIconColor: '#fff',
    menuIconOpacity: '0.3',
    menuIconSecondaryOpacity: '1',
    firstColorIconIdle: '#0169EC',
    loadButtonColor: '#0169EC',
    firstColorIconActive: '#0169EC',
    secondColorIconIdle: '#0169EC',
    secondColorIconActive: '#0169EC',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#0169EC',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      secondaryOpacity: '1',
      mainColor: '#fff',
      secondColor: '#fff',
      activeMainColor: '#0169EC',
      activeSecondColor: '#0169EC',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#0169EC',
      secondIcon: '#0169EC',
      opacity: '0.3',
    },
  },
  default : {
    logoIconColor: '#fff',
    infoIcon: '#1BB961',
    linkIcon: '#4661C4',
    firstClipBoardColor: '#ff0032',
    secondClipBoardColor: '#4661C4',
    menuIconColor: '#4661C4',
    enterIconColor: '#fff',
    menuIconOpacity: '0.3',
    menuIconSecondaryOpacity: '1',
    firstColorIconIdle: '#4661C4',
    loadButtonColor: '#4661C4',
    firstColorIconActive: '#4661C4',
    secondColorIconIdle: '#4661C4',
    secondColorIconActive: '#4661C4',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#4661C4',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      secondaryOpacity: '1',
      mainColor: '#fff',
      secondColor: '#fff',
      activeMainColor: '#4661C4',
      activeSecondColor: '#4661C4',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#4661C4',
      secondIcon: '#4661C4',
      opacity: '0.3',
    },
  },
  'pharmacy-366': {
    logoIconColor: '#fff',
    infoIcon: '#1BB961',
    linkIcon: '#004B80',
    firstClipBoardColor: '#ff0032',
    secondClipBoardColor: '#004B80',
    menuIconColor: '#004B80',
    enterIconColor: '#fff',
    menuIconOpacity: '0.3',
    menuIconSecondaryOpacity: '1',
    firstColorIconIdle: '#004B80',
    loadButtonColor: '#004B80',
    firstColorIconActive: '#004B80',
    secondColorIconIdle: '#004B80',
    secondColorIconActive: '#004B80',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#004B80',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      secondaryOpacity: '1',
      mainColor: '#fff',
      secondColor: '#fff',
      activeMainColor: '#004B80',
      activeSecondColor: '#004B80',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#004B80',
      secondIcon: '#004B80',
      opacity: '0.3',
    },
  },
  megafon : {
    logoIconColor: '#fff',
    infoIcon: '#1BB961',
    linkIcon: '#4661C4',
    firstClipBoardColor: '#ff0032',
    secondClipBoardColor: '#4661C4',
    menuIconColor: '#4661C4',
    enterIconColor: '#fff',
    menuIconOpacity: '0.3',
    menuIconSecondaryOpacity: '1',
    firstColorIconIdle: '#4661C4',
    loadButtonColor: '#4661C4',
    firstColorIconActive: '#4661C4',
    secondColorIconIdle: '#4661C4',
    secondColorIconActive: '#4661C4',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#4661C4',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      secondaryOpacity: '1',
      mainColor: '#fff',
      secondColor: '#fff',
      activeMainColor: '#4661C4',
      activeSecondColor: '#4661C4',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#4661C4',
      secondIcon: '#4661C4',
      opacity: '0.3',
    },
  },
  'rus-exp' : {
    logoIconColor: '#fff',
    infoIcon: '#1BB961',
    linkIcon: '#E92B37',
    firstClipBoardColor: '#6E6E8A',
    secondClipBoardColor: '#6E6E8A',
    menuIconColor: '#E92B37',
    enterIconColor: '#E92B37',
    menuIconOpacity: '1',
    menuIconSecondaryOpacity: '0.3',
    firstColorIconIdle: '#6E6E8A',
    loadButtonColor: '#E92B37',
    firstColorIconActive: '#E92B37',
    secondColorIconIdle: '#6E6E8A',
    secondColorIconActive: '#E92B37',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#E92B37',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      secondaryOpacity: '0.3',
      mainColor: '#6E6E8A',
      secondColor: '#6E6E8A',
      activeMainColor: '#E92B37',
      activeSecondColor: '#E92B37',
      opacity: '1',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#E92B37',
      secondIcon: '#E92B37',
      opacity: '0.3',
    },
  },
  'leader-abc' : {
    logoIconColor: '#fff',
    infoIcon: '#1BB961',
    linkIcon: '#ED1C24',
    firstClipBoardColor: '#6E6E8A',
    secondClipBoardColor: '#6E6E8A',
    menuIconColor: '#ED1C24',
    enterIconColor: '#ED1C24',
    menuIconOpacity: '1',
    menuIconSecondaryOpacity: '1',
    firstColorIconIdle: '#6E6E8A',
    loadButtonColor: '#ED1C24',
    firstColorIconActive: '#ED1C24',
    secondColorIconIdle: '#6E6E8A',
    secondColorIconActive: '#ED1C24',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#333333',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      secondaryOpacity: '0.3',
      mainColor: '#6E6E8A',
      secondColor: '#6E6E8A',
      activeMainColor: '#ED1C24',
      activeSecondColor: '#ED1C24',
      opacity: '1',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#ED1C24',
      secondIcon: '#ED1C24',
      opacity: '0.3',
    },
  },
  'mascoglass' : {
    logoIconColor: '#fff',
    infoIcon: '#1BB961',
    linkIcon: '#FFDD00',
    firstClipBoardColor: '#95A2AA',
    secondClipBoardColor: '#95A2AA',
    menuIconColor: '#FFDD00',
    enterIconColor: '#FFDD00',
    menuIconOpacity: '1',
    menuIconSecondaryOpacity: '1',
    firstColorIconIdle: '#95A2AA',
    loadButtonColor: '#FFDD00',
    firstColorIconActive: '#FFDD00',
    secondColorIconIdle: '#95A2AA',
    secondColorIconActive: '#FFDD00',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#333333',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      secondaryOpacity: '1',
      mainColor: '#95A2AA',
      secondColor: '#95A2AA',
      activeMainColor: '#030303',
      activeSecondColor: '#FFDD00',
      opacity: '1',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#FFDD00',
      secondIcon: '#FFDD00',
      opacity: '0.3',
    },
  },
  aclub : {
    logoIconColor: '#fff',
    infoIcon: '#1BB961',
    linkIcon: '#EF3124',
    firstClipBoardColor: '#EF3124',
    secondClipBoardColor: '#505759',
    menuIconColor: '#505759',
    enterIconColor: '#fff',
    menuIconOpacity: '0.3',
    menuIconSecondaryOpacity: '1',
    firstColorIconIdle: '#505759',
    loadButtonColor: '#505759',
    firstColorIconActive: '#EF3124',
    secondColorIconIdle: '#505759',
    secondColorIconActive: '#EF3124',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#505759',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      secondaryOpacity: '1',
      mainColor: '#505759',
      secondColor: '#505759',
      activeMainColor: '#EF3124',
      activeSecondColor: '#EF3124',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#505759',
      secondIcon: '#505759',
      opacity: '0.3',
    },
  },
  'womens-day' : {
    logoIconColor: '#fff',
    infoIcon: '#1BB961',
    linkIcon: '#4661C4',
    firstClipBoardColor: '#ff0032',
    secondClipBoardColor: '#4661C4',
    menuIconColor: '#4661C4',
    enterIconColor: '#fff',
    menuIconOpacity: '0.3',
    menuIconSecondaryOpacity: '1',
    firstColorIconIdle: '#4661C4',
    loadButtonColor: '#4661C4',
    firstColorIconActive: '#4661C4',
    secondColorIconIdle: '#4661C4',
    secondColorIconActive: '#4661C4',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#4661C4',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      secondaryOpacity: '1',
      mainColor: '#fff',
      secondColor: '#fff',
      activeMainColor: '#4661C4',
      activeSecondColor: '#4661C4',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#4661C4',
      secondIcon: '#4661C4',
      opacity: '0.3',
    },
  },
  'sargazarm' : {
    logoIconColor: '#fff',
    infoIcon: '#1BB961',
    linkIcon: '#4661C4',
    firstClipBoardColor: '#ff0032',
    secondClipBoardColor: '#4661C4',
    menuIconColor: '#4661C4',
    enterIconColor: '#fff',
    menuIconOpacity: '0.3',
    menuIconSecondaryOpacity: '1',
    firstColorIconIdle: '#4661C4',
    loadButtonColor: '#4661C4',
    firstColorIconActive: '#4661C4',
    secondColorIconIdle: '#4661C4',
    secondColorIconActive: '#4661C4',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#4661C4',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      secondaryOpacity: '1',
      mainColor: '#fff',
      secondColor: '#fff',
      activeMainColor: '#4661C4',
      activeSecondColor: '#4661C4',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#4661C4',
      secondIcon: '#4661C4',
      opacity: '0.3',
    },
  },
  'asgbonus': {
    logoIconColor: '#fff',
    infoIcon: '#1BB961',
    linkIcon: '#4661C4',
    firstClipBoardColor: '#ff0032',
    secondClipBoardColor: '#4661C4',
    menuIconColor: '#4661C4',
    enterIconColor: '#fff',
    menuIconOpacity: '0.3',
    menuIconSecondaryOpacity: '1',
    firstColorIconIdle: '#4661C4',
    loadButtonColor: '#4661C4',
    firstColorIconActive: '#4661C4',
    secondColorIconIdle: '#4661C4',
    secondColorIconActive: '#4661C4',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#4661C4',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      secondaryOpacity: '1',
      mainColor: '#fff',
      secondColor: '#fff',
      activeMainColor: '#4661C4',
      activeSecondColor: '#4661C4',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#4661C4',
      secondIcon: '#4661C4',
      opacity: '0.3',
    },
  },
  'proektirovanie' : {
    logoIconColor: '#fff',
    infoIcon: '#1BB961',
    linkIcon: '#4661C4',
    firstClipBoardColor: '#ff0032',
    secondClipBoardColor: '#4661C4',
    menuIconColor: '#4661C4',
    enterIconColor: '#fff',
    menuIconOpacity: '0.3',
    menuIconSecondaryOpacity: '1',
    firstColorIconIdle: '#4661C4',
    loadButtonColor: '#4661C4',
    firstColorIconActive: '#4661C4',
    secondColorIconIdle: '#4661C4',
    secondColorIconActive: '#4661C4',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#4661C4',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      secondaryOpacity: '1',
      mainColor: '#fff',
      secondColor: '#fff',
      activeMainColor: '#4661C4',
      activeSecondColor: '#4661C4',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#4661C4',
      secondIcon: '#4661C4',
      opacity: '0.3',
    },
  },
  geo : {
    logoIconColor: '#fff',
    infoIcon: '#1BB961',
    linkIcon: '#4661C4',
    firstClipBoardColor: '#ff0032',
    secondClipBoardColor: '#4661C4',
    menuIconColor: '#4661C4',
    enterIconColor: '#fff',
    menuIconOpacity: '0.3',
    menuIconSecondaryOpacity: '1',
    firstColorIconIdle: '#4661C4',
    loadButtonColor: '#4661C4',
    firstColorIconActive: '#4661C4',
    secondColorIconIdle: '#4661C4',
    secondColorIconActive: '#4661C4',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#4661C4',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      secondaryOpacity: '1',
      mainColor: '#fff',
      secondColor: '#fff',
      activeMainColor: '#4661C4',
      activeSecondColor: '#4661C4',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#4661C4',
      secondIcon: '#4661C4',
      opacity: '0.3',
    },
  },
  loto: {
    logoIconColor: '#fff',
    infoIcon: '#1BB961',
    linkIcon: '#4661C4',
    firstClipBoardColor: '#ff0032',
    secondClipBoardColor: '#4661C4',
    menuIconColor: '#4661C4',
    enterIconColor: '#fff',
    menuIconOpacity: '0.3',
    menuIconSecondaryOpacity: '1',
    firstColorIconIdle: '#4661C4',
    loadButtonColor: '#4661C4',
    firstColorIconActive: '#4661C4',
    secondColorIconIdle: '#4661C4',
    secondColorIconActive: '#4661C4',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#4661C4',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      secondaryOpacity: '1',
      mainColor: '#fff',
      secondColor: '#fff',
      activeMainColor: '#4661C4',
      activeSecondColor: '#4661C4',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#4661C4',
      secondIcon: '#4661C4',
      opacity: '0.3',
    },
  },
  'loto-pr': {
    logoIconColor: '#fff',
    infoIcon: '#1BB961',
    linkIcon: '#2F41B0',
    firstClipBoardColor: '#ff0032',
    secondClipBoardColor: '#2F41B0',
    menuIconColor: '#2F41B0',
    enterIconColor: '#fff',
    menuIconOpacity: '0.3',
    menuIconSecondaryOpacity: '1',
    firstColorIconIdle: '#2F41B0',
    loadButtonColor: '#2F41B0',
    firstColorIconActive: '#2F41B0',
    secondColorIconIdle: '#2F41B0',
    secondColorIconActive: '#2F41B0',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#2F41B0',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      secondaryOpacity: '1',
      mainColor: '#fff',
      secondColor: '#fff',
      activeMainColor: '#2F41B0',
      activeSecondColor: '#2F41B0',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#2F41B0',
      secondIcon: '#2F41B0',
      opacity: '0.3',
    },
  },
  'birthday': {
    logoIconColor: '#fff',
    infoIcon: '#1BB961',
    linkIcon: '#fe4608',
    firstClipBoardColor: '#ff0032',
    secondClipBoardColor: '#fe4608',
    menuIconColor: '#fe4608',
    enterIconColor: '#fff',
    menuIconOpacity: '0.3',
    menuIconSecondaryOpacity: '1',
    firstColorIconIdle: '#000',
    loadButtonColor: '#fe4608',
    firstColorIconActive: '#fe4608',
    secondColorIconIdle: '#000',
    secondColorIconActive: '#fe4608',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#fe4608',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#27AE60',
      noVerified: '#F45384',
    },
    tabMenu: {
      secondaryOpacity: '1',
      mainColor: '#000',
      secondColor: '#000',
      activeMainColor: '#fe4608',
      activeSecondColor: '#fe4608',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '0.3'
    },
    crossIcon: {
      mainIcon: '#fe4608',
      secondIcon: '#fe4608',
      opacity: '0.3',
    },
  },
  'raiffeisen': {
    logoIconColor: '#2B2D33',
    infoIcon: '#479A78',
    linkIcon: '#2B2D33',
    firstClipBoardColor: '#2B2D33',
    secondClipBoardColor: '#2B2D33',
    menuIconColor: '#2B2D33',
    enterIconColor: '#2B2D33',
    menuIconOpacity: '0.3',
    menuIconSecondaryOpacity: '1',
    firstColorIconIdle: '#2B2D33',
    loadButtonColor: '#2B2D33',
    firstColorIconActive: '#2B2D33',
    secondColorIconIdle: '#2B2D33',
    secondColorIconActive: '#2B2D33',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#2B2D33',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#479A78',
      noVerified: '#CD5A50',
    },
    tabMenu: {
      secondaryOpacity: '1',
      mainColor: '#2B2D33',
      secondColor: '#2B2D33',
      activeMainColor: '#2B2D33',
      activeSecondColor: '#2B2D33',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '1'
    },
    crossIcon: {
      mainIcon: '#2B2D33',
      secondIcon: '#2B2D33',
      opacity: '0.3',
    },
  },
  'brucite': {
    logoIconColor: '#5AC3BE',
    infoIcon: '#5AC3BE',
    linkIcon: '#5AC3BE',
    firstClipBoardColor: '#5AC3BE',
    secondClipBoardColor: '#969FA8',
    menuIconColor: '#5AC3BE',
    enterIconColor: '#5AC3BE',
    menuIconOpacity: '0.3',
    menuIconSecondaryOpacity: '1',
    firstColorIconIdle: '#969FA8',
    loadButtonColor: '#2B2D33',
    firstColorIconActive: '#5AC3BE',
    secondColorIconIdle: '#969FA8',
    secondColorIconActive: '#5AC3BE',
    firstOpacity: '0.3',
    secondOpacity: '0.3',
    continueIconColor: '#2B2D33',
    cartIconOpacity: '0.3',
    letter: {
      verified: '#479A78',
      noVerified: '#CD5A50',
    },
    tabMenu: {
      secondaryOpacity: '1',
      mainColor: '#5AC3BE',
      secondColor: '#969FA8',
      activeMainColor: '#5AC3BE',
      activeSecondColor: '#969FA8',
      opacity: '0.3',
    },
    orderStatusReady: {
      mainColor: '#fff',
      opacity: '1'
    },
    crossIcon: {
      mainIcon: '#5AC3BE',
      secondIcon: '#969FA8',
      opacity: '0.3',
    },
  },
};
