import {createAsyncThunk, createSlice, createAction} from '@reduxjs/toolkit';
import axios from 'axios';

import ax from 'utils/ax';
import {StorageItemsNames} from 'enums';
import {hidePopup, showPopup} from './PopupSlice';
import {getUser, sendEmailVerified} from './ProfileSlice';
import {setPurse} from './DashboardSlice';
import {getPathURL, baseURL} from 'env/env.json';

export interface IPurse {
  id: number;
  balance: number;
  bank_commission: string | null;
  phone_commission: string | null;
  has_tax: boolean;
  plastic_included: boolean;
  is_taxable_payments: boolean;
  is_taxable_eps: boolean;
  tax_payer: 'customer' | 'participant';
  min_payment_commission?: number;
}

export interface INDFL {
  id: string,
  created_at: string,
  status: number,
  comment?: string,
  status_label: string
}

export interface IUser {
  id: string;
  name: string;
  phone: string;
  email: string;
  purses: IPurse[];
  status: number;
  email_verified_at: string | null;
  created_at: string;
  updated_at: string;
  token: string;
  is_new_user: boolean;
  refresh_token?: string;
  code_data?: { message: string, status: string };
  tax: null | INDFL;
  ndfl_auth_token?: string,
}

interface ResultIGetCode {
  data: {
    code?: string,
    status?: string,
    data: IGetCode
  }
}

interface ResultIGetPath {
  data: {
    data: {
      code?: string,
      status?: string,
      result: IGetPath
    }
  }
}

export interface IAuth {
  authorized: boolean;
  is_required_email: boolean;
  user: IUser | undefined;
  numberCard: string[] | [];
  projectSettings?: any;
  routerHistory: any;
}

export interface IGetCode {
  message: string;
  user?: IUser;
  error_code?: string;
}

export interface IGetPath {
  url: string;
}

type GetCodeType = {
  phone: string,
  send_code?: string,
  captcha?: string;
  captcha_version?: string;
}

export const resetState = createAction('resetState');

export const getCode = createAsyncThunk(
  'auth/getCode',
  async ({phone, send_code, captcha, captcha_version}: GetCodeType) => {
    const replacedPhone = phone.replace(/[^\d+]/g, '');
    return await ax().post('/api/auth/step1', {phone: replacedPhone, send_code, captcha, captcha_version})
      .then(({data}: ResultIGetCode) => {
        return data.data;
      });
  }
);

export const getNDFLAuthToken = createAsyncThunk(
  'auth/getNDFLAuthToken',
  async () => {
    return await ax().get('/api/tax/check')
      .then(({data}: any) => {
        return data.data.result;
      });
  }
);

export const getPath = createAsyncThunk(
  'auth/getPath',
  async ({cardNumber, setError}: {cardNumber: number, setError: any}) => {
    try {
      const {data: {data}} =  await ax().get(`api/project/url?cardNumber=${cardNumber}`);

      return data;
    } catch (e: any) {
      const result = e.response.data.data.result;

      setError && setError(result.code[0]);
    }
  }
);

export const getNumberCard = createAsyncThunk(
  'auth/getNumberCard',
  async ({phone, token}: {phone: string; token: string}) => {
    return await ax().post(
      '/api/cards/get-activated-cards',
      {phone: phone.replace(/[^\d+]/g, '')},
      {headers: {'Authorization': `Bearer ${token}`}})
      .then(({data}: any) => {
        return data.data.result.cards;
      });
  }
);

export const sendCode = createAsyncThunk(
  'auth/sendCode',
  async (payload: {
    phone: string;
    code: string;
    setError?: any;
    showRegisterPopup?: any;
    rememberCardNumber: boolean;
  }, {dispatch}) => {

    try {
      payload.setError('');
      const replacedPhone = payload.phone.replace(/[^\d+]/g, '');

      const response = await ax()
        .post('/api/auth/step2', {phone: replacedPhone, code: payload.code});

      const {data} = response.data;

      const auth = async () => {
        dispatch(hidePopup('auth'));
        dispatch(hidePopup('checkNumberCard'));
        await localStorage.setItem(StorageItemsNames.USER, JSON.stringify(data.user));
        dispatch(setPurse(data.user.purses[0]));
        dispatch(authRedirect());
      };

      if (data.code === 'error') {
        payload.setError && payload.setError(data.result);
      } else {

        await localStorage.setItem(StorageItemsNames.TOKEN, data.user.token);
        await localStorage.setItem(StorageItemsNames.REFRESH_TOKEN, data.user.refresh_token);

        if (payload.rememberCardNumber) {
          dispatch(getNumberCard({phone: payload.phone.replace(/[^\d+]/g, ''), token: data.user.token}))
            .then(() => {
              dispatch(hidePopup('auth'));
              dispatch(showPopup({popup: 'checkNumberCard', props: {
                onClick: auth,
              }}));
            });
        } else if (data.user.is_new_user || !data.user.name) {
          dispatch(getProjectSettings('registration'));
          payload.showRegisterPopup && payload.showRegisterPopup();
        } else {
          dispatch(getProjectSettings('registration'));
          auth();
        }

        return data.user;
      }
    } catch (e: any) {
      const result = e.response.data.data.result;

      payload.setError && payload.setError(result.code[0]);
    }
  }
);

export const signUp = createAsyncThunk(
  'auth/signUp',
  async (payload: {
    email: string;
    name: string;
    surname: string;
    history: any;
    captcha: string;
    token: string;
    refreshToken: string;
  }, {getState, dispatch}) => {
    const {auth}: any = getState();
    let response;

    try {
      const {origin} = window.location;
      response = await axios.post(baseURL + 'api/user/register', {
        email: payload.email || '',
        name: payload.name,
        surname: payload.surname,
        phone: auth.user && `+${auth.user.phone.replace(/[^\d+]/g, '')}`,
        captcha_response: payload.captcha,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${payload.token}`,
          'Referer': origin.replace('www.', ''),
        }
      });
    } catch (e: any) {
      if (e.response.status === 422) {
        response = e.response;
      }
    }

    const {data} = response.data;

    if (data.code !== 'error') {

      await localStorage.setItem(StorageItemsNames.TOKEN, payload.token);
      await localStorage.setItem(StorageItemsNames.REFRESH_TOKEN, payload.refreshToken);
      await dispatch(getUser()).then(() => dispatch(authRedirect()));
      await dispatch(hidePopup('register'));
      await payload.history.push('/dashboard/prizes');
      await dispatch(showPopup('activationCard'));
    }

    return data;
  }
);

export const authRedirect = createAsyncThunk(
  'auth/authRedirect',
  async () => {
    const userStorage = await JSON
      .parse(localStorage.getItem(StorageItemsNames.USER) || '{}');

    return userStorage;
  }
);

export const getProjectSettings = createAsyncThunk(
  'prizes/getProjectSettings',
  async (payload: string, {getState}) => {
    const {dashboard}: any = getState();

    try {
      const {data} = await ax().get('api/project/settings', {params: {
        key: payload,
        value: payload === 'registration' ? window.location.origin + '/' : dashboard.purse.id,
      }});

      if (data.data.code !== 'error') {
        return data.data.result;
      }
    } catch (e) {
      console.log(e);
    }
  }
);

export const logOutAndResetStore = createAsyncThunk(
  'auth/logOutAndResetStore',
  async (_, {dispatch}) => {
    dispatch(logOut());
    dispatch(resetState());
  }
);

const AuthSlice = createSlice({
  name: 'auth',
  initialState: {
    authorized: false,
    user: undefined,
    is_required_email: true,
    numberCard: [],
    projectSettings: {},
    routerHistory: null,
  },
  reducers: {
    signInLaravel: () => {
      try {
        ax().get('/sanctum/csrf-cookie');
      } catch (e) {
        console.log(e);
      }
    },
    setRouterHistory(state, {payload}) {
      state.routerHistory = payload;
    },
    logOut: (state: IAuth) => {
      const storageValues = Object.values(StorageItemsNames);

      try {
        ax().post('api/auth/logout');

        storageValues.map((item) => {
          if (item !== StorageItemsNames.CARD_NUMBER) {
            localStorage.removeItem(item);
          }
        });

        state.authorized = false;
        state.user = undefined;
      } catch (e) {
        console.log(e);
      }
    },
    setUser: (state: IAuth, payload) => {
      state.user = payload.payload;
    },
    resetPurses: (state: IAuth, payload) => {
      if (state.user && state.user.purses) {
        state.user.purses = payload.payload;
      }
    },
    setAuthorized: (state: IAuth, payload) => {
      state.authorized = payload.payload;
    },
    // setBalanceCurrentPurse: (state: IAuth, payload) => {
    //   state.user?.purses[payload.payload.index] = payload.payload.value;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(sendCode.fulfilled, (state: IAuth, action) => {
      state.user = {...action.payload, code_data: state.user && state.user.code_data };
    });
    builder.addCase(getCode.fulfilled, (state: IAuth, {payload}) => {
      if (state.user) {
        if(payload.code === 'error') {
          state.user.code_data = {message: payload.result, status: 'error'};
        }
        console.log(payload.message);
        if (payload.message) {
          state.user.code_data = {message: payload.message, status: 'ok'};
        }
      }

    });
    builder.addCase(getNumberCard.fulfilled, (state: IAuth, {payload}) => {
      state.numberCard = payload;
    });
    builder.addCase(signUp.fulfilled, (state: IAuth, {payload}) => {
      const user = payload.result[0];

      if (user && user.token) {
        state.user = user;
        localStorage.setItem(StorageItemsNames.USER, JSON.stringify(user));
      }
    });
    builder.addCase(getNDFLAuthToken.fulfilled, (state: IAuth, {payload}: {payload: any}) => {
      if (state.user) {
        state.user = {
          ...state.user,
          tax: state.user.tax ? {
            ...state.user.tax,
            status: payload.status,
            status_label: payload.status_label,
            created_at: payload.created_at || state.user.tax.created_at,
          } : null,
          ndfl_auth_token: payload.token,
        };
      }
    });
    builder.addCase(authRedirect.fulfilled, (state: IAuth, action) => {
      state.user = action.payload;

      const userIsNull = state.user && Object.keys(state.user).length > 0;

      if (userIsNull) {
        state.authorized = true;
      } else {
        state.authorized = false;
      }
    });
    builder.addCase(getProjectSettings.fulfilled, (state: IAuth, {payload}: any) => {
      if (payload) {
        state.projectSettings = payload;
        state.is_required_email = !payload.without_email;
      }
    });
  },
});

export const {
  logOut,
  signInLaravel,
  setUser,
  resetPurses,
  setRouterHistory
} = AuthSlice.actions;

export default AuthSlice.reducer;

